<template>
	<div>
		<div class="competitionForm" v-if="joinStatus">
			<el-form 
				:model="competitionForm" 
				:rules="rules" 
				ref="competitionForm" 
				label-width="100px"
			>
				<el-form-item label="大赛标题" ref="title" prop="title" class="w580">
					<el-input 
						
						placeholder="请输入大赛标题" 
						v-model="competitionForm.title"
					></el-input>
				</el-form-item>
				
				<el-form-item label="大赛封面" ref="cover" prop="cover">
					<el-upload 
						class="avatar-uploader" 
						:show-file-list="false" 
						:auto-upload="true"
						:action="$ROOT_DIR + '/comms/Release/uploadPics?token=' + token"
						accept="image/png,image/jpg,image/jpeg" 
						:on-success="handleAvatarSuccess"
						:before-upload="beforeAvatarUpload"
					>
						<img v-if="competitionForm.cover" :src="competitionForm.cover" class="avatar" />
						<i v-else class="el-icon-plus"></i>
						<div v-if="!competitionForm.cover" class="el-upload__text uploadTx">
							请上传封面
						</div>
						<div class="el-upload__tip f12 c_bf" slot="tip" style="line-height: 0.25rem">
							提示：建议上传格式为JPG/PNG/JPEG且高宽比为5:3图片
						</div>
					</el-upload>
				</el-form-item>
				
				<el-form-item label="征集时间" required class="w580">
					<el-col :span="11">
						<el-form-item ref="levy_start_time" prop="levy_start_time">
							<el-date-picker 
								type="date" 
								placeholder="选择日期" 
								v-model="competitionForm.levy_start_time"
								value-format="timestamp"
							>
							</el-date-picker>
						</el-form-item>
					</el-col>
					<el-col class="line" :span="2">-</el-col>
					<el-col :span="11">
						<el-form-item ref="levy_end_time" prop="levy_end_time">
							<el-date-picker
								type="date" 
								placeholder="选择日期" 
								v-model="competitionForm.levy_end_time"
								value-format="timestamp"
							>
							</el-date-picker>
						</el-form-item>
					</el-col>
				</el-form-item>
				
				<el-form-item label="评审时间" required class="w580">
					<el-col :span="11">
						<el-form-item ref="review_start_time" prop="review_start_time">
							<el-date-picker 
								type="date" 
								placeholder="选择日期" 
								v-model="competitionForm.review_start_time"
								value-format="timestamp"
							>
							</el-date-picker>
						</el-form-item>
					</el-col>
					<el-col class="line" :span="2">-</el-col>
					<el-col :span="11">
						<el-form-item ref="review_end_time" prop="review_end_time">
							<el-date-picker 
								type="date" 
								placeholder="选择时间" 
								v-model="competitionForm.review_end_time"
								value-format="timestamp"
							>
							</el-date-picker>
						</el-form-item>
					</el-col>
				</el-form-item>
				
				<el-form-item label="公示时间" class="w580">
					<el-date-picker 
						type="date" 
						placeholder="选择日期" 
						v-model="competitionForm.exhibit_time"
						value-format="timestamp"
					>
					</el-date-picker>
				</el-form-item>
				
				<el-form-item label="大赛类型" prop="fidPath">
					<el-select
						v-model.number="competitionForm.fidPath"
						placeholder="请选择分类"
						@change="selectClassType"
					>
						<el-option
							v-for="(item, index) in classList"
							:key="index"
							:label="item.name"
							:value="item.id"
						></el-option>
					</el-select>
				</el-form-item>
				
				<el-form-item label="上传附件" class="uploadFile w580">
					<div>
						<el-button 
							size="small" 
							type="primary"
							:class="{ noActive: competitionFileObj.isFile }" 
							@click="zh_uploadFile"
						>
							点击上传
						</el-button>
						<input 
							accept=".zip,.rar,.7z" 
							type="file" 
							ref="evfile"
							@change="zh_uploadFile_change" 
							style="display: none" 
						/>
					</div>
					<div class="el-upload__tip">
						提示：附件以rar、zip、7z压缩包格式上传,文件大小需小于500M
					</div>
					
					<div class="uploadFileList" v-show="competitionFileObj.isFile">
						<div class="uploadFileItem mt30">
							<div class="fileName">
								<i class="iconfont">&#xef55;</i>
								<p class="f14 c_33 txt-over">{{ competitionFileObj.suFile }}</p>
							</div>
							<!-- 上传中展示进度 -->
							<div class="uploadStatus" v-show="!competitionFileObj.isSucceed">
								<p class="mr10">上传中</p>
								<el-progress 
									class="statusBar" 
									:percentage="competitionFileObj.suFileUploadSchedule"
									color="#F46600">
								</el-progress>
							</div>
							<div class="uploadStatus" v-show="competitionFileObj.isSucceed">
								<p class="mr10">上传完成</p>
							</div>
							
							<div class="uploadSet">
								<i class="iconfont" v-show="!competitionFileObj.isSucceed && competitionFileObj.isStop"
									@click="startUpload">&#xe688;</i>
								<i class="iconfont" v-show="!competitionFileObj.isSucceed && !competitionFileObj.isStop"
									@click="stopUpload">&#xe65b;</i>
								<i class="iconfont" @click="cancelUpload">&#xe789;</i>
							</div>
						</div>
					</div>
					<div class="accessory" v-if="competitionForm.attachment.length">
						<div class="el-upload__tip">
							已上传附件
						</div>
						<div class="accessory" v-for="(item, index) in competitionForm.attachment" :key="index">
							<div class="accessoryTitle">
								<i class="el-icon-paperclip"></i>
								<div class="txt-over">
									{{item.title}}
								</div>
								<i class="el-icon-delete" @click="deleteAtt(index)"></i>
							</div>
						</div>
					</div>
				</el-form-item>
				
				<!-- 大赛详情 -->
				<div v-for="(item, index) in competitionForm.detail" :key="index">
					<el-form-item 
						:label="index == 0?'大赛详情':''" 
						:ref="'detail.' + index + '.title'"
						:prop="'detail.' + index + '.title'"
						:rules="{
							required: true, message: '请输入标题', trigger: 'blur'
						}"
						class="w580">
						<div class="flexSubtitle">
							<el-input placeholder="标题" v-model="item.title"></el-input>
							<i v-if="index != 0" @click="removeWork(item, index)" title="删除" class="el-icon-circle-close"></i>
						</div>
					</el-form-item>
					<el-form-item 
						:ref="'detail.' + index + '.content'"
						:prop="'detail.' + index + '.content'"
						:rules="{
							required: true, message: '请编辑详情', trigger: 'blur'
						}"
						class="w900 ueditor">
						<vue-ueditor-wrap v-model="item.content" :config="editorConfig"></vue-ueditor-wrap>
					</el-form-item>
				</div>
				
				<div class="addNewWork" @click="addNewWork">添加新标题</div>
				

				<el-form-item>
					<el-button :loading="submitLoading" type="primary" @click="submitForm()">提交审核</el-button>
				</el-form-item>
			</el-form>
		</div>
	</div>
</template>
<script>
	import VueUeditorWrap from "vue-ueditor-wrap";
	import {
		DesignCompetitionEdit,
		DesignCompetitionEditDetail,
		DesignCompetitionAdd,
		getProNavList,
		DesignCompetitionDelSection,
		// uploadImg
	} from "../../../Api";
	import { qiniuUpload } from "../../../utils/upload";
	import { mapGetters } from "vuex";
	import {
		getToken
	} from "@/utils/auth";

	export default {
		name: "CompetitionForm",
		components: {
			VueUeditorWrap,
		},
		data() {
			return {
				id: '',
				submitLoading: null,
				joinStatus: true,
				token: "",
				
				uploadObj: null,	// 上传对象
				competitionFileObj: {
					isStop: false,
					isSucceed: false,
					isFile: false,
					suFile: "",
					suFileUploadSchedule: 0,
				},
				
				competitionForm: {
					title: "",	// 大赛标题
					cover: "",	// 大赛封面
					levy_start_time: "",	// 征集开始时间
					levy_end_time: "",	// 征集结束时间
					review_start_time: "",	// 评审开始时间
					review_end_time: "",	// 评审结束时间
					exhibit_time: "",	// 公式时间
					fidPath: "",	//大赛类型
					attachment: [],	// 附件数组
					detail: [{
						id: '',
						title: '',
						content: '',
					}],	// 大赛详情
				},
				classList: [],
				rules: {
					title: [{
						required: true,
						message: "请输入标题",
						trigger: "blur"
					}],
					cover: [{
						required: true,
						message: "请上传封面图片",
						trigger: "change"
					}, ],
					levy_start_time: [{
						required: true,
						message: "请选择征集开始时间",
						trigger: "change"
					}, ],
					levy_end_time: [{
						required: true,
						message: "请选择征集结束时间",
						trigger: "change"
					}, ],
					review_start_time: [{
						required: true,
						message: "请选择评审开始时间",
						trigger: "change"
					}, ],
					review_end_time: [{
						required: true,
						message: "请选择评审结束时间",
						trigger: "change"
					}, ],
					fidPath: [{
						required: true,
						message: "请选择评大赛类型",
						trigger: "change"
					}, ],
				},

				editorConfig: {
					toolbars: [
						[
							// 'fullscreen',
							'source', '|', 'undo', 'redo', '|',
							'bold', 'italic', 'underline', 'fontborder', 'strikethrough', 'superscript', 'subscript',
							'removeformat', 'formatmatch', 'autotypeset', 'blockquote', 'pasteplain', '|', 'forecolor',
							'backcolor', 'insertorderedlist', 'insertunorderedlist', 'selectall', 'cleardoc', '|',
							'rowspacingtop', 'rowspacingbottom', 'lineheight', '|',
							'customstyle', 'paragraph', 'fontfamily', 'fontsize', '|',
							'directionalityltr', 'directionalityrtl', 'indent', '|',
							'justifyleft', 'justifycenter', 'justifyright', 'justifyjustify', '|', 'touppercase',
							'tolowercase', '|',
							'link', 'unlink', 'anchor', '|', 'imagenone', 'imageleft', 'imageright', 'imagecenter',
							'|',
							'simpleupload', 'insertimage', 'emotion', 'scrawl', 'insertvideo', 'music', 'attachment',
							'map', 'gmap', 'insertframe', 'insertcode', 'webapp', 'pagebreak', 'template',
							'background', '|',
							'horizontal', 'date', 'time', 'spechars', 'snapscreen', 'wordimage', '|',
							'inserttable', 'deletetable', 'insertparagraphbeforetable', 'insertrow', 'deleterow',
							'insertcol', 'deletecol', 'mergecells', 'mergeright', 'mergedown', 'splittocells',
							'splittorows', 'splittocols', 'charts', '|',
							'print', 'preview', 'searchreplace', 'drafts', 'help'
						]
					],
					// 编辑器不自动被内容撑高
					autoFloatEnabled: false,
					// 初始容器高度
					initialFrameHeight: 300,
					// 初始容器宽度
					initialFrameWidth: "120%",
					// 调试完毕打包上线则切换回/static/UEditor/php/controller.php即可，不用做其他处理
					serverUrl: "https://www.zhishew.com/edu/ueditor/php/controller.php",
					UEDITOR_HOME_URL: "/UEditor/",
				},
				
				editForm: "",
			};
		},
		computed: {
			...mapGetters(["uploadTokenInfo"]),
		},
		created() {
			getProNavList({ id: 2185, token: getToken()}).then((res) => {
				this.classList = res.data;
			})
			const query = JSON.parse(JSON.stringify(this.$route.query)); // 获取路由参数信息
			if (query.id) {
				this.id = query.id;
				this.geiDetail()
			}
			this.joinStatus = true;
			this.token = getToken();
		},
	
		methods: {
			// 初始化
			initial() {
				this.competitionForm = {
					title: "",	// 大赛标题
					cover: "",	// 大赛封面
					levy_start_time: "",	// 征集开始时间
					levy_end_time: "",	// 征集结束时间
					review_start_time: "",	// 评审开始时间
					review_end_time: "",	// 评审结束时间
					exhibit_time: "",	// 公式时间
					fidPath: "",	//大赛类型
					attachment: [],	// 附件数组
					detail: [{
						id: '',
						title: '',
						content: '',
					}],	// 大赛详情
				}
			},
			// 获取编辑竞赛（获取详情）
			geiDetail() {
				DesignCompetitionEditDetail({id: this.id, token: getToken()}).then((res)=>{
					let detailData = res.data
					detailData.levy_start_time = detailData.levy_start_time*1000
					detailData.levy_end_time = detailData.levy_end_time*1000
					detailData.review_start_time = detailData.review_start_time*1000
					detailData.review_end_time = detailData.review_end_time*1000
					detailData.exhibit_time = detailData.exhibit_time*1000
					let fidPathArr = detailData.fidPath.split(',')
					detailData.fidPath = Number(fidPathArr[fidPathArr.length-1])
					
					detailData.attachment = detailData.attachment ? detailData.attachment : [],
					detailData.detail = detailData.detail ? detailData.detail : [{ id: '', title: '', content: '', }],
					this.competitionForm = detailData
				})
			},
			/* 图片上传 */
			// 上传成功
			handleAvatarSuccess(res) {
				if (res.data.message == "uploadSuccess") {
					this.competitionForm.cover = res.data.filePath;
				}
			},
			// 上传图片前的图片验证回调
			beforeAvatarUpload(file) {
				const _this = this;
				const isSize = new Promise(function (resolve, reject) {
					const img = new Image();
					const _URL = window.URL || window.webkitURl;
					img.onload = function () {
						file.width = img.width; //图片宽度
						file.height = img.height; //图片高度
						const valid = (img.height / img.width).toFixed(2) == 0.6;
						valid ? resolve() : reject(new Error("error"));
					};
					img.src = _URL.createObjectURL(file);
				}).then(
					() => {
						return file;
					},
					() => {
						_this.$message.error("上传格式为JPG/PNG/JPEG且高宽比为5:3图片");
						return Promise.reject(new Error("error"));
					}
				);
				return isSize;
			},
			// 选择分类
			selectClassType(val) {
				console.log(val);
			},
			/* 上传附件 */
			// 点击上传附件
			zh_uploadFile() {
				if (this.competitionFileObj.isFile) return;
				this.$refs.evfile.click();
			},
			//获取七牛云上传对象
			zh_uploadFile_change(evfile) {
				const file = evfile.target.files[0];
				if (!file || !file.name) return
				this.competitionFileObj.isFile = true;
				this.competitionFileObj.suFile = file.name;
				qiniuUpload(file, this.uploadTokenInfo.uptoken).then((res) => {
					this.observable = res;
					this.startUpload();
				});
			},
			//开始上传
			startUpload() {
				this.competitionFileObj.isStop = false;
				this.uploadObj = this.observable.subscribe({
					next: (result) => {
						this.competitionFileObj.suFileUploadSchedule = Math.floor(
							result.total.percent
						);
					},
					error: (errResult) => {
						this.$message.error("上传失败");
						console.log(errResult);
					},
					complete: (result) => {
						let fileObj = {}
						fileObj.title = this.competitionFileObj.suFile
						fileObj.src = this.uploadTokenInfo.domain + result.key;
						// const index = result.key.lastIndexOf("."); //得到最后一个点的坐标
						// fileObj.fileName = result.key.substring(0, index);
						this.competitionForm.attachment.splice(0, 0, fileObj)
						this.competitionFileObj.isSucceed = true;
						this.cancelUpload()
					},
				});
			},
			//暂停上传
			stopUpload() {
				this.competitionFileObj.isStop = true;
				this.uploadObj.unsubscribe();
			},
			//取消上传
			cancelUpload() {
				if (this.uploadObj) this.uploadObj.unsubscribe();
				this.competitionFileObj = {
					isStop: false,
					isSucceed: false,
					isFile: false,
					suFile: "",
					suFileUploadSchedule: 0,
				};
				this.uploadObj = null;
				this.observable = null;
			},
			// 删除附件
			deleteAtt(index) {
				this.competitionForm.attachment.splice(index, 1)
			},
			// 添加新标题
			addNewWork() {
				let newWork = {
					id: '',
					title: '',
					content: '',
				}
				let num = this.competitionForm.detail.length
				this.competitionForm.detail.splice(num, 0, newWork)
			},
			// 删除标题
			removeWork(item, index) {
				if(this.id && item.id) {
					DesignCompetitionDelSection({id: item.id, token: getToken()}).then(res => {
						if (res.data.error === 0) {
							this.competitionForm.detail.splice(index, 1)
						} else {
							this.$message.error("删除失败");
						}
					})
				} else {
					this.competitionForm.detail.splice(index, 1)
				}
			},
			/* 
			uploadFiles(data) {
				return new Promise((resolve, reject) => {
					let formData = new FormData();
					formData.append("file", data);
					uploadImg(getToken(), formData)
						.then((res) => {
							if (res.data.message === "uploadSuccess") {
								resolve(res.data.filePath);
							} else {
								console.log("上传失败");
								reject(res);
							}
						})
						.catch((error) => {
							console.log("错误", error);
							reject(error);
						});
				});
			},
			
			getBase64Image(img) {
				const canvas = document.createElement('canvas')
				canvas.width = img.width
				canvas.height = img.height
				const ctx = canvas.getContext('2d')
				ctx?.drawImage(img, 0, 0, img.width, img.height)
				let ext = img.src.substring(img.src.lastIndexOf('.') + 1).toLowerCase()
				if (ext === 'jpg') {
					ext = 'jpeg' //这个地方是由于如果是jpg, 他会识别成image/png
				}
				const dataURL = canvas.toDataURL('image/' + ext)
				return dataURL
			},
			// 下载图片
			phoneDown (imgUrl) {
				let _this = this;
				return new Promise((resolve, reject) => {
					let image = new Image()
					image.setAttribute('crossOrigin', 'anonymous')	// 解决跨域 Canvas 污染问题
					// image.crossOrigin = ''
					image.src = imgUrl
					image.onload = function() {
						console.log(image.complete, 123);
						resolve(_this.getBase64Image(image));
					}
					image.onerror = function(err) {
						console.log(err, 456);
						reject(err)
					}
				})
			},
			
			// 获取或替换img src
			replaceImga(content, img) {
				var b = /<img [^>]*src=['"]([^'"]+)[^>]*>/g;// img 标签取src里面内容的正则
				var s = img.match(b);// 取到所有img标签 放到数组 s里面
				var srcImg = s[0].replace(b, '$1');//取src面的内容
				
				console.log(srcImg);
				this.phoneDown(srcImg)
				console.log(this.phoneDown(srcImg), 'this.phoneDown(srcImg)');
				
				//修改富文本字符串内容 img标签src 相对路径改为绝对路径
				// _str = _str.replace(/src=['"]([^'"]+)([^>]*)>/gi, 'src="' + 12345 + '"'+ '>'); 
				// _str = _str.replace(/src=['"]([^'"]+)([^>]*)>/gi, `src="123" ${'$2'}>`); 
				// detailA=detailA.replace(item, _str)
			},
			
			// 文本
			// 提取img
			// 循环
			// 	下载
			// 	上传
			// 	还原
			// 返回新文本
			replaceImg(content) {
				let r = RegExp(/<[img]+\s+(.*?)(?<id>\w*?)[\s'"](.*?)>/g);
				let matchres = content.match(r);	// 获取文档中全部img
				
				console.log("matchres===", matchres)
				// this.replaceImga(content, matchres[0])
				
				return new Promise((resolve, reject) => {
					let len = matchres.length;
					let uploadCot = 0;
					if( matchres ){
						matchres.forEach((item)=>{
								
							this.phoneDown(item).then((dataURL)=>{
								console.log(dataURL, "下载成功");
								this.uploadFiles(dataURL).then((url)=> {
									console.log(url, "下载成功");
									
									uploadCot++
									if (uploadCot == len) {
										resolve();
									}
								}).catch(err => {
									reject(err)
									console.log(err);
								})
							}).catch(err => {
								reject(err)
								console.log(err);
							})
							let _str = item;
							content.replace(item, _str)	// 将修改完成的img放回原位
						})
					}
				});
			}, */
			
			// 提交审核
			submitForm() {
				// console.log(this.competitionForm, 'this.competitionForm');
				// let detailA = this.competitionForm.detail[0].content 
				// this.replaceImg(detailA)
				// this.actContents = parseHtml(contents);
				// this.phoneDowns('https://gd-hbimg.huaban.com/cc6bd15dc0e14d0ab76bc49f1643f7d7341ccd6839ec6-kUIdv9_fw240webp')
				
				this.$refs.competitionForm.validate((valid, object) => {
					if (valid) {
						let params = JSON.parse(JSON.stringify(this.competitionForm));
						params.levy_start_time = Math.floor(params.levy_start_time/1000)
						params.levy_end_time = Math.floor(params.levy_end_time/1000)
						params.review_start_time = Math.floor(params.review_start_time/1000)
						params.review_end_time = Math.floor(params.review_end_time/1000)
						params.exhibit_time = Math.floor(params.exhibit_time/1000)
						params.token = this.token
						if (this.id) {
							params.id = this.id
							this.editData(params);
						} else{
							this.saveData(params);
						}
					} else {
						let flag = true;
						let obj1 = Object.keys(object)[0];
						for( let i of Object.keys(this.rules)) {
							if (Object.keys(object).indexOf(i) >= 0) {
								if(this.$refs[i]) {
									flag = false
									this.$refs[i].$el.scrollIntoView({
										behavior: "smooth",
										block: 'center'
									})
									break;
								}
							}
						}
						if (flag && obj1) {
							if (this.$refs[obj1]&&this.$refs[obj1][0]) {
								this.$refs[obj1][0].$el.scrollIntoView({
									behavior: "smooth",
									block: 'center',
								})
							}
						}
					}
				});
			},
			saveData(params) {
				this.submitLoading = true;
				DesignCompetitionAdd(params).then((res)=>{
					if (res.data.error === 0) {
						this.submitLoading = false;
						this.$message.success("发布成功！等待平台审核");
						this.initial()
					} else {
						this.$message.error("发布失败！请刷新页面重新填写");
					}
				}).catch((err)=>{
					this.submitLoading = false;
					console.log(err);
				})
			},
			editData(params) {
				this.submitLoading = true;
				DesignCompetitionEdit(params).then((res)=>{
					if (res.data.error === 0) {
						this.submitLoading = false;
						this.$parent.changeType('uploadList')
						this.$message.success("编辑成功！等待平台审核");
					} else {
						this.$message.error("编辑失败！请刷新页面重新填写");
					}
				}).catch((err)=>{
					this.submitLoading = false;
					console.log(err);
				})
			}
		},
	};
</script>
<style scoped>
	.addNewWork {
		width: 1.2rem;
		height: 0.4rem;
		margin: 0 1rem 0.4rem;
		border-radius: 0.02rem;
		background: rgba(255, 233, 191, 1);
		border: 1px solid rgba(255, 140, 26, 1); 
		font-size: 0.14rem;
		font-weight: 400;
		text-align: center;
		line-height: 0.4rem;
		color: rgba(255, 140, 26, 1);
		cursor: pointer;
	}
	.accessoryTitle {
		display: flex;
		align-items: center;
		font-size: 0.14rem
	}
	.accessoryTitle .txt-over {
		width: 3rem;
	}
	.el-icon-paperclip {
		margin-right: 0.05rem;
	}
	.el-icon-delete {
		color: red;
		margin-left: 0.6rem;
	}
	.flexSubtitle {
		display: flex;
		align-items: center;
	}
	.flexSubtitle .el-icon-circle-close {
		color: #bebaba;
		font-size: 0.24rem;
		margin-left: 0.2rem;
	}
	.line {
		text-align: center;
	}
	.w580 {
		width: 580px;
	}
	.noActive {
		background: #bfbfbf !important;
		border-color: #bfbfbf !important;
	}
	.uploadSet {
		flex: 1;
		font-size: 0.25rem;
		text-align: right;
		color: #666666;
	}
	.avatar-uploader-icon {
		font-size: 0.28rem;
		color: #8c939d;
		text-align: center;
	}
	.uploadSet i {
		margin: 0 0.05rem;
		cursor: pointer;
	}
	.statusBar {
		flex: 1;
	}
	.uploadStatus {
		width: 3rem;
		display: flex;
		align-items: center;
	}
	.uploadFileItem {
		width: 6.56rem;
		height: 0.4rem;
		display: flex;
		align-items: center;
		font-size: 0.14rem;
		color: #333333;
		justify-content: space-between;
		background: #ffffff;
		box-shadow: 0px 0px 0.06rem rgba(102, 102, 102, 0.2);
		border-radius: 0.04rem;
		padding: 0 0.2rem;
	}
	.fileName {
		display: flex;
		align-items: center;
		/*line-height: 0.2rem;*/
		width: 2rem;
		margin-right: 0.5rem;
	}
	
	.fileName i {
		color: #7a7dff;
		font-size: 0.18rem;
		margin-right: 0.08rem;
	}
	.uploadFile >>> .el-upload__tip {
		line-height: 0.18rem;
	}
	.competitionForm {
		width: 100%;
		padding: 0.2rem 0 0.4rem;
		background: #ffffff;
		border-radius: 0.04rem;
	}

	.segmentation {
		width: 0.2rem;
		text-align: center;
	}

	.competitionForm>>>.uploadTx {
		font-size: 0.12rem;
		color: #bfbfbf;
		padding: 0 0.1rem;
		top: 0.85rem;
		position: absolute;
		line-height: 0.2rem;
		width: 2rem;
	}

	.competitionForm>>>.el-radio__label {
		font-size: 00.14rem;
	}

	.competitionForm>>>.el-form-item {
		margin-bottom: 0.4rem;
	}

	.competitionForm>>>.el-form-item .el-form-item {
		margin-bottom: 0;
	}

	.competitionForm>>>.el-radio__input.is-checked+.el-radio__label {
		color: #f46600;
	}

	.competitionForm>>>.upload-demo .el-upload {
		border: 0;
		line-height: 40px;
		height: 40px;
		width: 4rem;
	}

	.competitionForm>>>.upload-demo .el-button--primary {
		width: 4rem;
		font-size: 00.14rem;
	}

	.competitionForm>>>.upload-demo .el-upload-list {
		width: 6.55rem;
	}

	.competitionForm>>>.upload-demo .el-upload-list__item {
		box-shadow: 0px 0px 0.06rem rgb(102 102 102 / 20%);
		height: 0.4rem;
		line-height: 0.4rem;
	}

	.competitionForm>>>.el-upload {
		width: 2rem;
		height: 1.2rem;
		background-color: #fff;
		border-radius: 0.06rem;
		box-sizing: border-box;
		text-align: center;
		position: relative;
		overflow: hidden;
		line-height: 1.2rem;
		background: #f7f6fb;
		border: none !important;
	}

	.competitionForm>>>.el-radio__input.is-checked .el-radio__inner {
		border-color: #f46600;
		background: #f46600;
	}

	.competitionForm>>>.el-button--primary {
		width: 1.55rem;
		background-color: #f46600;
		border-color: #f46600;
		font-size: 00.14rem;
	}

	.competitionForm>>>.el-button+.el-button {
		font-size: 0.14rem;
	}

	.competitionForm>>>.el-input {
		font-size: 0.14rem;
	}

	.competitionForm>>>.el-input__inner {
		height: 0.4rem;
		line-height: 0.4rem;
	}

	.competitionForm>>>.el-icon-plus {
		font-size: 28px;
		color: #8c939d;
	}

	.w900>>>.edui-default .edui-editor {
		z-index: 1 !important;
	}

	.avatar {
		width: 2rem;
	}

	.qrCodeUpload {
		width: 2rem;
		height: 2rem;
	}

	.qrCodeUpload>>>.el-upload {
		width: 2rem;
		height: 2rem;
	}

	#editor_qukvxyhm {
		width: 9rem !important;
	}

	.ueditor>>>.el-form-item__content {
		line-height: 0;
	}
	
	.competitionForm>>>.edui-editor-iframeholder {
		max-height: 500px !important;
	}
</style>
