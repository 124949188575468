<template>
  <div>
		<div class="competitionBox">
			<div class="boxLeft">
				<div class="xiding">
					<div class="uploadSidebarItem" :class="{ active: activeName == 'addUpload' }" @click="changeType('addUpload')">
						<span class="f14 f_b">添加竞赛</span>
						<i class="iconfont f16" style="margin-left: auto">&#xe665;</i>
					</div>
					<div class="uploadSidebarItem" :class="{ active: activeName == 'uploadList' }" @click="changeType('uploadList')">
						<span class="f14 f_b">已添加竞赛列表</span>
						<i class="iconfont f16" style="margin-left: auto">&#xe665;</i>
					</div>
					<div class="uploadSidebarItem" :class="{ active: activeName == 'contact' }" @click="changeType('contact')">
						<span class="f14 f_b">联系方式</span>
						<i class="iconfont f16" style="margin-left: auto">&#xe665;</i>
					</div>
				</div>
			</div>
			<div class="boxRight">
				<CompetitionForm v-if="activeName == 'addUpload'"></CompetitionForm>
				<uploadList v-if="activeName == 'uploadList'"></uploadList>
				<contact v-if="activeName == 'contact'"></contact>
			</div>
		</div>
		
  </div>
</template>

<script>
import CompetitionForm from "./componts/CompetitionForm";
import uploadList from "./componts/uploadList";
import contact from "./componts/contact";
import {
	// DesignCompetitionGetList,
} from '@/Api'

export default {
  name: "uploadCompetition",
  data() {
    return {
      activeName: 'addUpload',
    };
  },
  components: {
		CompetitionForm,
		uploadList,
		contact
	},
  created() {
    //将参数设置到地址栏
    const query = JSON.parse(JSON.stringify(this.$route.query)); // 获取路由参数信息
    this.activeName = query.activeName ? query.activeName : 'addUpload'; //改变参数
    this.$router.push({ path: this.$route.path, query });
    this.$store.dispatch("upload/getUploadToken").then(() => {});
  },
  methods: {
		changeType(type, id) {
			let query= {
				activeName: '',
				id: ''
			};
			if (type) {
				this.activeName = type;
				query.activeName = type;
			}
			if (id) query.id = id;
			this.$router.push({
				path: "/competition/uploadCompetition",
				query
			});
		},
  },
};
</script>

<style scoped>
.competitionBox {
		width: 100%;
		padding: 0.24rem 2.8rem;
		display: flex;
		justify-content: space-between;
		background-color: #f7f6fb;
}
.boxLeft {
	width: 2.4rem;
	min-width: 2.4rem;
	padding-right: 0.2rem;
}
.xiding {
  position: sticky;
  top: 0.68rem;
}
.boxRight {
	flex: 1;
}
.uploadSidebarItem.active {
	background: #f46600;
	color: #ffffff;
}
.uploadSidebarItem {
	background-color: #ffffff;
	cursor: pointer;
	color: #333333;
	display: flex;
	align-items: center;
	padding: 0.15rem 0.2rem 0.15rem 0.3rem;
	border-radius: 0.04rem;
}
.courseTabs {
  width: 11rem;
  height: 0.82rem;
  background: #ffffff;
  opacity: 1;
  border-radius: 4px;
  margin-top: 0.1rem;
  display: flex;
  align-items: center;
}
.courseTabs div {
  width: 1.6rem;
  height: 0.42rem;
  background: #ffffff;
  border: 1px solid #999999;
  opacity: 1;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.14rem;
  font-family: PingFang SC;
  font-weight: bold;
  color: #666666;
  opacity: 1;
  cursor: pointer;
}
.activeDiv {
  border: 1px solid #f46600 !important;
  color: #f46600 !important;
}
.courseTabs div:nth-child(1) {
  margin-left: 0.3rem;
}
.courseTabs div:nth-child(2) {
  margin-left: 0.4rem;
}
</style>