<template>
	<div class="uploadListBox">
		<div>
			<el-form :inline="true" :model="searchData" class="demo-form-inline" @submit.native.prevent>
				<el-form-item>
					<el-date-picker
						v-model="searchData.time"
						type="daterange"
						unlink-panels
						:picker-options="pickerOptions"
						range-separator="至"
						start-placeholder="开始日期"
						end-placeholder="结束日期"
						value-format="timestamp"
						align="right">
					</el-date-picker>
				</el-form-item>
				<el-form-item>
					<el-input v-model="searchData.key" placeholder="请输入搜索内容"></el-input>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" native-type="submit" @click="onResearch">查询</el-button>
				</el-form-item>
			</el-form>
		</div>
		<div>
			<el-table
				v-loading="listLoading"
				:data="tableData" 
				border 
				@selection-change="handleSelectionChange"
			>
				<el-table-column fixed type="selection" width="39"></el-table-column>
				<el-table-column prop="title" label="竞赛名称" show-overflow-tooltip>
				</el-table-column>
				<el-table-column prop="date" label="征集开始时间" width="140">
					<template slot-scope="scope">
						{{scope.row.levy_start_time | formDate("yyyy-MM-dd")}}
					</template>
				</el-table-column>
				<el-table-column prop="date" label="征集结束时间" width="140">
					<template slot-scope="scope">
						{{scope.row.levy_end_time | formDate("yyyy-MM-dd")}}
					</template>
				</el-table-column>
				<el-table-column prop="state" label="竞赛状态" width="140">
					<template slot-scope="scope">
						<div v-if="scope.row.state == 1" class="competitionItemBut activeClass">
							未开始
						</div>
						<div v-else-if="scope.row.state == 2" class="competitionItemBut active">
							正在征集中
						</div>
						<div v-else-if="scope.row.state == 3" class="competitionItemBut">
							已结束
						</div>
					</template>
				</el-table-column>
				<el-table-column label="竞赛封面" width="90">
					<template slot-scope="scope">
						<img class="tabCover" :src="scope.row.img" alt="">
					</template>
				</el-table-column>
				<el-table-column fixed="right" label="操作" width="100">
					<template slot-scope="scope">
						<el-button @click="handleEdit(scope.row)" type="text" size="small">编辑</el-button>
						<el-button @click="handleDelete(scope.row)" type="text" size="small">删除</el-button>
					</template>
				</el-table-column>
			</el-table>
		</div>
		<div class="bottomPage">
			<el-pagination
				background
				@size-change="handleSizeChange"
				@current-change="handleCurrentChange"
				:current-page="listQuery.page"
				:page-size="listQuery.per_page"
				layout="total, prev, pager, next, jumper"
				:total="total"
				v-if="total"
				>
			</el-pagination>
		</div>
	</div>
</template>

<script>
	import {
		DesignCompetitionGetList,
		DesignCompetitionDel
	} from '@/Api'
	import {
		getToken
	} from "@/utils/auth";
	export default {
		name: "uploadList",
		props: ['bannerList'],
		data() {
			return {
				listLoading: false,
				total: 0,
				listQuery: {
					page: 1,
					per_page: 10
				},
				searchData: {
					key: '',
					time: '',
					start_time: '',
					end_time: ''
				},
				tableData: [],
				selectList: [],
				
				
				pickerOptions: {
					shortcuts: [{
						text: '最近一周',
						onClick(picker) {
							const end = new Date();
							const start = new Date();
							start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
							picker.$emit('pick', [start, end]);
						}
					}, {
						text: '最近一个月',
						onClick(picker) {
							const end = new Date();
							const start = new Date();
							start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
							picker.$emit('pick', [start, end]);
						}
					}, {
						text: '最近三个月',
						onClick(picker) {
							const end = new Date();
							const start = new Date();
							start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
							picker.$emit('pick', [start, end]);
						}
					}]
				},
			}
		},
		created() {
			this.getList()
		},
		methods: {
			// 编辑
			handleEdit(row) {
				this.$parent.changeType('addUpload', row.id)
				// this.$router.push({ path: "/competition/uploadCompetition", query: {activeName: 'addUpload', id: 123} });
			},
			// 删除
			handleDelete(row) {
				DesignCompetitionDel({id: row.id, token: getToken()}).then(res => {
					if (res.data.error === 0) {
						this.getList()
						this.$message.success("删除成功");
					} else {
						this.$message.error("删除失败");
					}
				})
			},
			// 选择列表列
			handleSelectionChange(rows) {
				this.selectList = rows;
			},
			// 查询
			onResearch() {
				this.listQuery.page = 1;
				this.searchData.start_time = this.searchData.time?Math.floor(this.searchData.time[0]/1000):''
				this.searchData.end_time = this.searchData.time?Math.floor(this.searchData.time[1]/1000):''
				this.getList()
			},
			// 获取列表
			getList() {
				this.listLoading = true;
				let params = {...this.listQuery, ...this.searchData}
				delete params.time
				params.token = getToken()
				DesignCompetitionGetList(params).then(res => {
					if (res.status === '200') {
						this.tableData = res.data.data;
						this.total = res.data.total;
						this.$nextTick(() => {
							this.listLoading = false;
						})
					}
				})
			},
			// 切换当前分页记录数
			handleSizeChange(val) {
				this.listQuery.per_page = val;
				this.getList()
			},
			// 当前页更换
			handleCurrentChange(val) {
				this.listQuery.page = val;
				this.getList()
			}
		},
		mounted() {

		}
	}
</script>

<style scoped>
	.uploadListBox {
		padding: 0.2rem;
		background: #ffffff;
		border-radius: 0.04rem;
	}
	.tabCover {
		width: 0.66rem;
		height: 0.4rem;
	}
	::v-deep .el-button--primary:first-child {
		/* width: 120px; */
		background-color: #ffffff;
		border-color: #d9d9d9;
		color: #FFF;
	}
	::v-deep .el-button--primary:last-child {
		/* width: 120px; */
		background-color: #f46600;
		border-color: #f46600;
	}
	.bottomPage {
		margin-top: 0.2rem;
	}
	/deep/ .el-pagination.is-background .btn-next {
		background: none;}
	/deep/ .el-pagination.is-background .btn-prev {
		background: none;
	}
	/deep/ .el-pagination.is-background .el-pager li {
		background: none;
	}
	/deep/ .el-date-editor .el-range-separator {
		width: 9%;
	}
</style>
