import { render, staticRenderFns } from "./uploadCompetition.vue?vue&type=template&id=6aeb5469&scoped=true&"
import script from "./uploadCompetition.vue?vue&type=script&lang=js&"
export * from "./uploadCompetition.vue?vue&type=script&lang=js&"
import style0 from "./uploadCompetition.vue?vue&type=style&index=0&id=6aeb5469&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6aeb5469",
  null
  
)

export default component.exports