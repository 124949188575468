<template>
	<div>
		<div class="competitionForm">
			<el-form 
				:model="competitionForm" 
				:rules="rules" 
				ref="competitionForm" 
				label-width="100px"
			>
			
				<el-form-item label="标题" ref="title" prop="title" class="w580">
					<el-input 
						placeholder="请输入标题" 
						v-model="competitionForm.title"
					></el-input>
				</el-form-item>
				
				<el-form-item label="详情" prop="contact_detail" class="w900 ueditor">
					<VueUeditorWrap v-model="competitionForm.contact_detail" :config="editorConfig"></VueUeditorWrap>
				</el-form-item>

				<el-form-item>
					<el-button :loading="submitLoading" type="primary" @click="submitForm()">提交审核</el-button>
				</el-form-item>
			</el-form>
		</div>
	</div>
</template>
<script>
	import VueUeditorWrap from "vue-ueditor-wrap";
	import { mapGetters } from "vuex";
	import {
		getToken
	} from "@/utils/auth";
	import {
		DesignCompetitionEditContactDetail,
		DesignCompetitionGetContactDetail
	} from "../../../Api";

	export default {
		name: "contact",
		components: {
			VueUeditorWrap,
		},
		data() {
			return {
				submitLoading: false,
				token: "",
				
				competitionForm: {
					title: "",	// 大赛标题
					contact_detail: '',
				},
				rules: {
					title: [{
						required: true,
						message: "请输入标题",
						trigger: "blur"
					}],
					contact_detail: [{
						required: true, 
						message: '请编辑详情', 
						trigger: 'blur'
					}, ],
				},

				editorConfig: {
					toolbars: [
						[
							// 'fullscreen',
							'source', '|', 'undo', 'redo', '|',
							'bold', 'italic', 'underline', 'fontborder', 'strikethrough', 'superscript', 'subscript',
							'removeformat', 'formatmatch', 'autotypeset', 'blockquote', 'pasteplain', '|', 'forecolor',
							'backcolor', 'insertorderedlist', 'insertunorderedlist', 'selectall', 'cleardoc', '|',
							'rowspacingtop', 'rowspacingbottom', 'lineheight', '|',
							'customstyle', 'paragraph', 'fontfamily', 'fontsize', '|',
							'directionalityltr', 'directionalityrtl', 'indent', '|',
							'justifyleft', 'justifycenter', 'justifyright', 'justifyjustify', '|', 'touppercase',
							'tolowercase', '|',
							'link', 'unlink', 'anchor', '|', 'imagenone', 'imageleft', 'imageright', 'imagecenter',
							'|',
							'simpleupload', 'insertimage', 'emotion', 'scrawl', 'insertvideo', 'music', 'attachment',
							'map', 'gmap', 'insertframe', 'insertcode', 'webapp', 'pagebreak', 'template',
							'background', '|',
							'horizontal', 'date', 'time', 'spechars', 'snapscreen', 'wordimage', '|',
							'inserttable', 'deletetable', 'insertparagraphbeforetable', 'insertrow', 'deleterow',
							'insertcol', 'deletecol', 'mergecells', 'mergeright', 'mergedown', 'splittocells',
							'splittorows', 'splittocols', 'charts', '|',
							'print', 'preview', 'searchreplace', 'drafts', 'help'
						]
					],
					// 编辑器不自动被内容撑高
					autoFloatEnabled: false,
					// 初始容器高度
					initialFrameHeight: 300,
					// 初始容器宽度
					initialFrameWidth: "120%",
					// 调试完毕打包上线则切换回/static/UEditor/php/controller.php即可，不用做其他处理
					serverUrl: "https://www.zhishew.com/edu/ueditor/php/controller.php",
					UEDITOR_HOME_URL: "/UEditor/",
				},
				
				editForm: "",
			};
		},
		computed: {
			...mapGetters(["uploadTokenInfo"]),
		},
		created() {
			this.token = getToken();
			DesignCompetitionGetContactDetail({token: getToken()}).then((res)=>{
				let detailData = res.data
				if (detailData) {
					this.competitionForm = detailData
				}
			})
		},
	
		methods: {
			// 提交审核
			submitForm() {
				this.$refs.competitionForm.validate((valid) => {
					if (valid) {
						this.saveData();
					}
				});
			},
			saveData() {
				this.submitLoading = true;
				DesignCompetitionEditContactDetail(this.competitionForm).then((res)=>{
					if (res.data.error === 0) {
						this.submitLoading = false;
						this.$message.success("编辑成功！");
					} else {
						this.submitLoading = false;
						this.$message.error("编辑失败！请刷新页面重新填写");
					}
				}).catch((err)=>{
					console.log(err);
					this.submitLoading = false;
				})
			},
		},
	};
</script>
<style scoped>
	.addNewWork {
		width: 1.2rem;
		height: 0.4rem;
		margin: 0 1rem 0.4rem;
		border-radius: 0.02rem;
		background: rgba(255, 233, 191, 1);
		border: 1px solid rgba(255, 140, 26, 1); 
		font-size: 0.14rem;
		font-weight: 400;
		text-align: center;
		line-height: 0.4rem;
		color: rgba(255, 140, 26, 1);
		cursor: pointer;
	}
	.accessoryTitle {
		display: flex;
		align-items: center;
		font-size: 0.14rem
	}
	.accessoryTitle .txt-over {
		width: 3rem;
	}
	.el-icon-paperclip {
		margin-right: 0.05rem;
	}
	.el-icon-delete {
		color: red;
		margin-left: 0.6rem;
	}
	.flexSubtitle {
		display: flex;
		align-items: center;
	}
	.flexSubtitle .el-icon-circle-close {
		color: #bebaba;
		font-size: 0.24rem;
		margin-left: 0.2rem;
	}
	.line {
		text-align: center;
	}
	.w580 {
		width: 580px;
	}
	.noActive {
		background: #bfbfbf !important;
		border-color: #bfbfbf !important;
	}
	.uploadSet {
		flex: 1;
		font-size: 0.25rem;
		text-align: right;
		color: #666666;
	}
	.avatar-uploader-icon {
		font-size: 0.28rem;
		color: #8c939d;
		text-align: center;
	}
	.uploadSet i {
		margin: 0 0.05rem;
		cursor: pointer;
	}
	.statusBar {
		flex: 1;
	}
	.uploadStatus {
		width: 3rem;
		display: flex;
		align-items: center;
	}
	.uploadFileItem {
		width: 6.56rem;
		height: 0.4rem;
		display: flex;
		align-items: center;
		font-size: 0.14rem;
		color: #333333;
		justify-content: space-between;
		background: #ffffff;
		box-shadow: 0px 0px 0.06rem rgba(102, 102, 102, 0.2);
		border-radius: 0.04rem;
		padding: 0 0.2rem;
	}
	.fileName {
		display: flex;
		align-items: center;
		/*line-height: 0.2rem;*/
		width: 2rem;
		margin-right: 0.5rem;
	}
	
	.fileName i {
		color: #7a7dff;
		font-size: 0.18rem;
		margin-right: 0.08rem;
	}
	.uploadFile >>> .el-upload__tip {
		line-height: 0.18rem;
	}
	
	
	

	.competitionForm {
		width: 100%;
		padding: 0.2rem 0 0.4rem;
		background: #ffffff;
		border-radius: 0.04rem;
	}

	.segmentation {
		width: 0.2rem;
		text-align: center;
	}

	.competitionForm>>>.uploadTx {
		font-size: 0.12rem;
		color: #bfbfbf;
		padding: 0 0.1rem;
		top: 0.85rem;
		position: absolute;
		line-height: 0.2rem;
		width: 2rem;
	}

	.competitionForm>>>.el-radio__label {
		font-size: 00.14rem;
	}

	.competitionForm>>>.el-form-item {
		margin-bottom: 0.4rem;
	}

	.competitionForm>>>.el-form-item .el-form-item {
		margin-bottom: 0;
	}

	.competitionForm>>>.el-radio__input.is-checked+.el-radio__label {
		color: #f46600;
	}

	.competitionForm>>>.upload-demo .el-upload {
		border: 0;
		line-height: 40px;
		height: 40px;
		width: 4rem;
	}

	.competitionForm>>>.upload-demo .el-button--primary {
		width: 4rem;
		font-size: 00.14rem;
	}

	.competitionForm>>>.upload-demo .el-upload-list {
		width: 6.55rem;
	}

	.competitionForm>>>.upload-demo .el-upload-list__item {
		box-shadow: 0px 0px 0.06rem rgb(102 102 102 / 20%);
		height: 0.4rem;
		line-height: 0.4rem;
	}

	.competitionForm>>>.el-upload {
		width: 2rem;
		height: 1.2rem;
		background-color: #fff;
		border-radius: 0.06rem;
		box-sizing: border-box;
		text-align: center;
		position: relative;
		overflow: hidden;
		line-height: 1.2rem;
		background: #f7f6fb;
		border: none !important;
	}

	.competitionForm>>>.el-radio__input.is-checked .el-radio__inner {
		border-color: #f46600;
		background: #f46600;
	}

	.competitionForm>>>.el-button--primary {
		width: 1.55rem;
		background-color: #f46600;
		border-color: #f46600;
		font-size: 00.14rem;
	}

	.competitionForm>>>.el-button+.el-button {
		font-size: 0.14rem;
	}

	.competitionForm>>>.el-input {
		font-size: 0.14rem;
	}

	.competitionForm>>>.el-input__inner {
		height: 0.4rem;
		line-height: 0.4rem;
	}

	.competitionForm>>>.el-icon-plus {
		font-size: 28px;
		color: #8c939d;
	}

	.w900>>>.edui-default .edui-editor {
		z-index: 1 !important;
	}

	.avatar {
		width: 2rem;
	}

	.qrCodeUpload {
		width: 2rem;
		height: 2rem;
	}

	.qrCodeUpload>>>.el-upload {
		width: 2rem;
		height: 2rem;
	}

	#editor_qukvxyhm {
		width: 9rem !important;
	}

	.ueditor>>>.el-form-item__content {
		line-height: 0;
	}
</style>
